import React, { useState } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Brush,
  Legend,
  ReferenceLine
} from 'recharts';
import * as XLSX from 'xlsx';
import { Clock, Calendar, Activity, ArrowUp, ArrowDown, Power } from 'lucide-react';
import './App.css';  // Asegúrate de tener un archivo CSS para estilos globales

export default function ForceChart() {
  const [chartData, setChartData] = useState([]);
  const [activeVariables, setActiveVariables] = useState({
    fuerza: true,
    ataque: false,
    salida: false,
    verticalidad: false
  });
  
  const [brushRange, setBrushRange] = useState({ startIndex: 0, endIndex: 1000 });

  const handleLoadFile = (fileName) => {
    fetch(`${process.env.PUBLIC_URL}/${fileName}`)
      .then(response => response.arrayBuffer())
      .then(data => {
        try {
          const workbook = XLSX.read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
  
          const parsedData = jsonData
            .slice(1)
            .map((row, index) => ({
              index: index,
              time: row[0] ? row[0].toString() : '',
              fuerza: parseFloat(row[1]),
              ataque: parseFloat(row[2]),
              salida: parseFloat(row[3]),
              verticalidad: parseFloat(row[4])
            }))
            .filter(item => !isNaN(item.fuerza) || !isNaN(item.ataque) || !isNaN(item.salida) || !isNaN(item.verticalidad));
  
          setChartData(parsedData);
        } catch (error) {
          console.error('Error al procesar el archivo:', error);
        }
      })
      .catch(error => {
        console.error('Error al cargar el archivo:', error);
      });
  };

  const formatXAxis = (tickItem) => {
    if (typeof tickItem === 'string' && tickItem.includes(':')) {
      return tickItem;
    }
    return tickItem;
  };

  const toggleVariable = (variable) => {
    setActiveVariables(prevState => ({
      ...prevState,
      [variable]: !prevState[variable]
    }));
  };

  const detectPeaks = (data, threshold = 5) => {
    const peaks = [];
    for (let i = 1; i < data.length - 1; i++) {
      if (data[i].fuerza >= data[i - 1].fuerza && data[i].fuerza >= data[i + 1].fuerza && data[i].fuerza > threshold) {
        peaks.push(data[i].time);
      }
    }
    return peaks;
  };

  const peaks = detectPeaks(chartData);

  const startTime = chartData[0]?.time || 'N/A';
  const endTime = chartData[chartData.length - 1]?.time || 'N/A';
  const duration = new Date(`1970-01-01T${endTime}`) - new Date(`1970-01-01T${startTime}`);
  const totalPaddles = peaks.length;
  const maxVerticalidad = Math.max(...chartData.map(d => d.verticalidad));
  const minVerticalidad = Math.min(...chartData.map(d => d.verticalidad));
  const totalPower = peaks.reduce((sum, peak) => sum + chartData.find(d => d.time === peak).fuerza, 0);

  return (
    <div className="bg-white rounded-lg shadow p-6 container">
      <h1 className="text-3xl font-bold text-center mb-8">🐲Análisis de Entrenamiento🐲</h1>
      
      {/* Botones de carga de archivos */}
      <div className="flex justify-center gap-4 mb-6">
        {[1, 2, 3, 4].map((num) => (
          <button
            key={num}
            onClick={() => handleLoadFile(`training_${num}.xlsx`)}
            className={`metric-card ${
              num === 1 ? 'blue-card' : 
              num === 2 ? 'green-card' : 
              num === 3 ? 'red-card' : 
              'yellow-card'
            } hover:scale-105 transition-transform px-6 py-3 rounded-lg flex items-center gap-2`}
          >
            <Activity size={20} />
            <span className="font-semibold">Cargar entrenamiento {num}</span>
          </button>
        ))}
      </div>

      {chartData.length > 0 && (
        <>
          <div className="text-center mb-8">
            <h3 className="text-xl font-semibold mb-4 text-gray-700">
              Selecciona variable para representar
            </h3>
            <div className="flex justify-center gap-4">
              {['fuerza', 'ataque', 'salida', 'verticalidad'].map(variable => (
                <button
                  key={variable}
                  onClick={() => toggleVariable(variable)}
                  className={`px-6 py-2 rounded-lg transition-all duration-200 transform hover:scale-105 ${
                    activeVariables[variable] 
                      ? 'bg-blue-500 text-white shadow-lg' 
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {variable.charAt(0).toUpperCase() + variable.slice(1)}
                </button>
              ))}
            </div>
          </div>

          <div style={{ width: '100%', height: 500 }}>
            <ResponsiveContainer>
              <LineChart
                data={chartData}
                margin={{ top: 10, right: 30, left: 0, bottom: 60 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis 
                  dataKey="time"
                  tickFormatter={formatXAxis}
                  interval={Math.ceil(chartData.length / 20)}
                  angle={-45}
                  textAnchor="end"
                  height={60}
                  tick={{ fontSize: 10 }}
                />
                <YAxis 
                  yAxisId="left" 
                  label={{ value: 'Fuerza (Kg)', angle: -90, position: 'insideLeft', offset: 10 }}
                />
                <YAxis 
                  yAxisId="right" 
                  orientation="right" 
                  label={{ value: 'Ángulos (Grados)', angle: -90, position: 'insideRight', offset: 10 }}
                />
                <Tooltip />
                <Legend />

                {activeVariables.fuerza && (
                  <Line 
                    yAxisId="left"
                    type="monotone"
                    dataKey="fuerza"
                    stroke="#2563eb"
                    dot={false}
                    strokeWidth={1.5}
                  />
                )}
                {activeVariables.ataque && (
                  <Line 
                    yAxisId="right"
                    type="monotone"
                    dataKey="ataque"
                    stroke="#ff0000"
                    dot={false}
                    strokeWidth={1.5}
                  />
                )}
                {activeVariables.salida && (
                  <Line 
                  yAxisId="right"
                  type="monotone"
                  dataKey="salida"
                  stroke="#10B981"  // Verde esmeralda
                  // Alternativas:
                  // stroke="#6366F1"  // Índigo
                  // stroke="#8B5CF6"  // Violeta
                  // stroke="#20B2AA"  // Verde azulado
                  dot={false}
                  strokeWidth={1.5}
                  name="Salida"
                  />
                )}
                {activeVariables.verticalidad && (
                  <Line 
                    yAxisId="right"
                    type="monotone"
                    dataKey="verticalidad"
                    stroke="#ffa500"
                    dot={false}
                    strokeWidth={1.5}
                  />
                )}

                {peaks.map((peakTime, index) => (
                  <ReferenceLine key={index} x={peakTime} yAxisId="left" stroke="red" strokeDasharray="3 3" />
                ))}

                <Brush 
                  dataKey="time"
                  height={40}
                  stroke="#ff0000"
                  startIndex={brushRange.startIndex}
                  endIndex={brushRange.endIndex}
                  onChange={(e) => setBrushRange({ startIndex: e.startIndex, endIndex: e.endIndex })}
                >
                  <LineChart>
                    <Line 
                      type="monotone" 
                      dataKey="fuerza" 
                      stroke="#8884d8" 
                      dot={false}
                      strokeWidth={1}
                    />
                  </LineChart>
                </Brush>
              </LineChart>
            </ResponsiveContainer>
          </div>

          {/* Sección de métricas */}
          <div className="dashboard-grid">
            <div className="metric-card blue-card">
              <div className="metric-card-header">
                <h3 className="metric-card-title">Fecha del entrenamiento</h3>
                <Calendar className="card-icon" size={24} />
              </div>
              <p className="metric-card-value">{startTime.split(' ')[0]}</p>
            </div>

            <div className="metric-card purple-card">
              <div className="metric-card-header">
                <h3 className="metric-card-title">Hora del entrenamiento</h3>
                <Clock className="card-icon" size={24} />
              </div>
              <p className="metric-card-value">{`${startTime} - ${endTime}`}</p>
            </div>

            <div className="metric-card green-card">
              <div className="metric-card-header">
                <h3 className="metric-card-title">Duración</h3>
                <Activity className="card-icon" size={24} />
              </div>
              <p className="metric-card-value">
                {new Date(duration).toISOString().substr(11, 8)}
              </p>
            </div>

            <div className="metric-card orange-card">
              <div className="metric-card-header">
                <h3 className="metric-card-title">Paladas totales</h3>
                <Activity className="card-icon" size={24} />
              </div>
              <p className="metric-card-value">{totalPaddles}</p>
            </div>

            <div className="metric-card red-card">
              <div className="metric-card-header">
                <h3 className="metric-card-title">Verticalidad máxima</h3>
                <ArrowUp className="card-icon" size={24} />
              </div>
              <p className="metric-card-value">{maxVerticalidad.toFixed(2)}°</p>
            </div>

            <div className="metric-card indigo-card">
              <div className="metric-card-header">
                <h3 className="metric-card-title">Verticalidad mínima</h3>
                <ArrowDown className="card-icon" size={24} />
              </div>
              <p className="metric-card-value">{minVerticalidad.toFixed(2)}°</p>
            </div>

            <div className="metric-card full-width yellow-card">
              <div className="metric-card-header">
                <h3 className="metric-card-title">Potencia total ejercida</h3>
                <Power className="card-icon" size={24} />
              </div>
              <p className="metric-card-value power-value">
                {totalPower.toFixed(2)} Kg
              </p>
            </div>
          </div>

          {/* Contador de puntos */}         
          <div className="mt-4 p-4 bg-gray-50 rounded">
            <p className="text-sm text-gray-600">
              Datos cargados: {chartData.length} puntos
            </p>
          </div>
        </>
      )}
    </div>
  );
}